import permissionStore from "./permissionsStore";
import tenantConfigurationStore from "./tenantConfigurationStore";

const STORES = [tenantConfigurationStore, permissionStore] as const;

export async function loadAllStores() {
  return Promise.all(STORES.map((store) => store.load()));
}

export async function removeAllStores() {
  return Promise.all(STORES.map((store) => store.remove()));
}
